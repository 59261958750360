import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { useProjectStore } from '@/store/project';
import { useValidationStore } from '@/store/validation';
import pinia from '@/store/store';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/403',
      name: '403',
      component: () => import('../views/errors/ErrorPage403.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/errors/ErrorPage404.vue'),
    },
    {
      path: '/500',
      name: '500',
      component: () => import('../views/errors/ErrorPage500.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/project/:id?',
      name: 'project',
      component: () => import('../views/ProjectView.vue'),
    },
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
  const project = useProjectStore(pinia);
  const validation = useValidationStore(pinia);
  const { setId, fetchData, market } = project;
  if (to.name === 'project') {
    // If the id has changed, reset project store

    if (to.params.id === 'new') {
      fetchData('new');
    } else if (to.params.id) {
      setId(to.params.id);
    }
  } else {
    const retain = { market }; // Market is set from the HomeView and needs to be kept
    project.$reset();
    project.$patch(retain);
    validation.$reset();
  }
});

export default router;
