import type { RoofArticle } from '@/calculation/common';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export enum Category {
  Takpannor,
  Tillbehör,
  Taksäkerhet,
  Tillval,
  Emballage,
}

const getCategories = (): { [key in Category]: string } => {
  return {
    [Category.Takpannor]: t('summary.category.roof_tiles'),
    [Category.Tillbehör]: t('summary.category.accessories'),
    [Category.Taksäkerhet]: t('summary.category.roof_security'),
    [Category.Tillval]: t('summary.category.extras'),
    [Category.Emballage]: t('summary.category.packaging'),
  };
};

export { getCategories };

export type ResultRow = {
  art: RoofArticle;
  artnr: string;
  total: number;
  category: Category;
  origins: {
    [key: string]: number;
  };
};

export type TotalTiles = {
  total: number;
  totalSvinn: number;
  totalRounded: number;
  tilePacking?: 'p' | 'bnt';
};

/** Add together two lists of results, will add the amount values of entries with equal category & artnr values */
export function addResults(resA: ResultRow[], resB: ResultRow[], origin?: string): ResultRow[] {
  const sum = [...resA];
  for (const b of resB) {
    let existing;
    if ((existing = sum.find((r) => r.artnr === b.artnr && r.category === b.category))) {
      existing.origins = { ...existing.origins, ...b.origins };
      if (origin) existing.origins[origin] = b.total;
      existing.total += b.total;
    } else {
      if (!b.origins) b.origins = {};
      if (origin) b.origins[origin] = b.total;
      sum.push(b);
    }
  }
  return sum;
}

/** Add a new row to a list or add the total if a row with this category & article number already exists */
export function addResult(list: ResultRow[], row: ResultRow, origin?: string) {
  let index: number;
  if ((index = list.findIndex((r) => r.artnr === row.artnr && r.category === row.category)) === -1) {
    list.push(row);
    if (origin) row.origins = { [origin]: row.total };
    return list;
  }

  list[index].total += row.total;
  if (origin) list[index].origins = { [origin]: row.total, ...list[index].origins };
  return list;
}

/** Remove result rows with a total of zero */
export function removeZeroQtyRows(rows: ResultRow[]) {
  for (let i = rows.length - 1; i >= 0; i--)
    // 999999 is used for articles we couldn't find article numbers for
    if (!rows[i].total || !rows[i].artnr || rows[i].artnr === '999999') rows.splice(i, 1);
}
