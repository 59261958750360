<script setup lang="ts">
import type { Market } from '@/types';

import { ref, computed, watch, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { useAccountStore } from '@/store/account';
import { useProjectStore } from '@/store/project';
import { MarketStr } from '@/calculation/common';

const projectStore = useProjectStore();
const project = reactive(projectStore);

const market = computed({
  get: () => {
    return project.$state as unknown as Market;
  },
  set: (value) => {
    if (prop.canSetMarket) projectStore.$patch(value);
  },
});

const route = useRoute();

const accountStore = useAccountStore();
const { t } = useI18n({ useScope: 'global' });

// Props & Emits
const prop = defineProps<{
  useProjectRegion?: boolean;
  canSetMarket?: boolean;
}>();

// Component
const marketPickerShowing = ref(false);
const markets = computed<
  {
    key: MarketStr;
    label: string;
    img: {
      flag: string;
      alt: string;
    };
    options: Market[];
  }[]
>(() => {
  const markets = [
    {
      key: MarketStr.Sverige,
      label: 'Sweden',
      img: {
        flag: 'se',
        alt: 'Sweden',
      },
      options: [
        {
          label: 'English',
          market: MarketStr.Sverige,
          language: 'en-US',
        },
        {
          label: 'Svenska',
          market: MarketStr.Sverige,
          language: 'sv-SE',
        },
        {
          label: 'Suomi',
          market: MarketStr.Sverige,
          language: 'fi-FI',
        },
        // {
        //   label: 'Deutsch',
        //   market: MarketStr.Sverige,
        //   language: 'de-DE',
        // },
      ] as Market[],
    },
    {
      key: MarketStr.Norge,
      label: 'Norway',
      img: {
        flag: 'no',
        alt: 'Norway',
      },
      options: [
        {
          label: 'English',
          market: MarketStr.Norge,
          language: 'en-US',
        },
        {
          label: 'Norsk, Bokmål',
          market: MarketStr.Norge,
          language: 'nb-NO',
        },
      ] as Market[],
    },
    {
      key: MarketStr.Tyskland,
      label: 'Germany',
      img: {
        flag: 'de',
        alt: 'Germany',
      },
      options: [
        {
          label: 'English',
          market: MarketStr.Tyskland,
          language: 'en-US',
        },
        {
          label: 'Deutsch',
          market: MarketStr.Tyskland,
          language: 'de-DE',
        },
      ] as Market[],
    },
  ];

  switch (accountStore.region) {
    case MarketStr.Norge:
      return markets.filter((m) => m.key === MarketStr.Norge);
    case MarketStr.Sverige:
      return markets.filter((m) => m.key === MarketStr.Sverige);
    case MarketStr.Tyskland:
      return markets.filter((m) => m.key === MarketStr.Tyskland);
    default:
      return markets;
  }
});

const regionLabel = computed<string>(() => {
  const label = markets.value.find((m) => m.key === market.value.market)?.label || '';
  return label ? t(`country.${label}`) : '';
});

watch(
  () => accountStore.getLanguage,
  (lang) => {
    // find the market that matches the language
    // prefer to stay on the same market if possible
    const possibleMarkets = markets.value.filter((m) => m.options.some((o) => o.language == lang));
    const selectedMarket = possibleMarkets.find((m) => m.key === market.value.market) || possibleMarkets[0];
    const option = selectedMarket.options.find((o) => o.language === lang) || selectedMarket.options[0];

    market.value = option;
  },
  { immediate: true },
);

// Language selection
watch(
  () => route.query,
  async () => {
    if (prop.useProjectRegion) {
      accountStore.setRegion(project.getMarket);
    } else if (route.query?.region) {
      const regionMap = {
        EDS: MarketStr.Sverige,
        SE: MarketStr.Sverige,
        SAN: MarketStr.Norge,
        NO: MarketStr.Norge,
        KRI: MarketStr.Tyskland,
        DE: MarketStr.Tyskland,
      } as { [key: string]: MarketStr };

      accountStore.setRegion(regionMap[route.query.region as string]);
    }
    if (route.query?.locale) {
      // Check if locale exists in the market
      const market = markets.value.find((m) => m.options.some((o) => o.language == route.query.locale));
      if (!market) {
        // Set default language to english in market
        const option = markets.value[0].options.find((o) => o.language === 'en-US') || markets.value[0].options[0];
        accountStore.setLanguage(option.language);
      } else {
        const option = market.options.find((o) => o.language === route.query.locale) || market.options[0];
        accountStore.setLanguage(option.language);
      }
    }
  },
  {
    immediate: true,
  },
);

function setMarket(value: Market) {
  accountStore.setLanguage(value.language);
  market.value = value;
}
</script>

<template>
  <div class="markets" data-has-children="true">
    <a @click.prevent="marketPickerShowing = !marketPickerShowing" href="#">Country ({{ regionLabel }})</a>
    <ul v-if="marketPickerShowing" @mouseleave="marketPickerShowing = false" @click.prevent="marketPickerShowing = false">
      <li v-for="market in markets" :key="market.key" class="two">
        <span :class="`fi fi-${market.img.flag}`" :alt="market.img.alt"></span>
        <ul>
          <li v-for="value in market.options" :key="value.language">
            <a @click.prevent="setMarket(value)" href="#"> {{ value.label }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style lang="sass" scoped>
.help-text
  text-align: center

.markets
  position: relative
  margin: 1.2rem 0

  &>a:after
    display: inline-block
    width: 0
    height: 0
    margin-left: 4px
    border: 4px solid transparent
    border-top-color: #58595b
    content: ""
    vertical-align: middle

  &>ul
    position: absolute
    top: 100%
    right: 30%
    z-index: 99
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    width: 360px
    margin: 0
    padding: 10px
    background: #f8f8f8
    text-align: left

  & li
    display: block
    position: relative
    float: left
    clear: both
    width: 100%
    margin: 0
    padding: 12px 0 6px
    border-bottom: 1px solid #dadada

.markets li.two li:after,
.markets li.two:after
  width: 8px
  height: 1px
  display: block
  background: #ddd
  content: ""
  position: absolute

.markets li ul
  float: left
  margin: 0 0 0 30px

.markets li a
  float: left
  padding: 0 3px 6px 15px
  font-weight: 400
  line-height: 20px
  text-decoration: none

.markets li:last-child
  border-bottom: none

.markets li img
  float: left
  padding-top: 3px

.markets li.two li
  padding: 0
  border: none

  &:after
    top: 9px

.markets li.two:after
  top: 50%
  bottom: 50%
  left: 22px
  margin-top: -1px

.markets li.two img
  position: absolute
  top: 50%
  left: 0
  margin: -7px 0 0
  padding-top: 0

.markets li.two ul:after
  display: block
  position: absolute
  top: 22px
  bottom: 22px
  width: 1px
  background: #ddd
  content: ""

.markets
  // margin: 20px 0
  font-size: 13px
  text-transform: uppercase

  & a
    font-size: 13px
    color: #58595b

  &>ul
    overflow: hidden
    margin: 0

@media (max-width:768px)
  .markets

    &>ul
      left: 0px
      width: 100%

.markets li.two .fi
  position: absolute
  top: 50%
  left: 0
  margin: -7px 0 0
  padding-top: 0
</style>
