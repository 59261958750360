<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { computedAsync } from '@vueuse/core';
import { useAccountStore } from '@/store/account';

import RegionSelector from '@/components/RegionSelector.vue';
import ProjectList from '@/components/ProjectList.vue';

const { getUser } = useAccountStore();

const projectListVisible = ref(false);
const allowProjectList = computedAsync<boolean | undefined>(
  async () => {
    const user = await getUser;
    if (user.isBendersEmployee || user.customers.length > 0) {
      return true;
    }
    return false;
  },
  false as boolean, // initial state
);
</script>

<template>
  <div>
    <p class="intro">{{ $t('main.intro') }}</p>

    <RegionSelector :canSetMarket="true" />

    <div class="new-calc">
      <router-link :to="{ name: 'project', params: { id: 'new' } }" class="is-link">{{ $t('main.start_project') }} </router-link>
    </div>

    <img src="@/assets/bild_loggin.png" alt="placeholder" class="image is-block" style="margin: 0 auto" width="300" height="188" />

    <template v-if="allowProjectList">
      <button @click.prevent="projectListVisible = !projectListVisible" class="toggle is-link is-hidden-desktop">
        {{ projectListVisible ? $t('main.hide_projects') : $t('main.show_projects') }}
      </button>

      <ProjectList :show="projectListVisible" class="mt-5" />
    </template>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.intro
  max-width: 670px
  margin: 0 auto

.image
  height: 200px

.new-calc
  margin-bottom: 1.2rem
  a
    position: relative
    padding-right: 1.55rem
    &:after
      content: ''
      display: block
      width: 0
      height: 0
      position: absolute
      border-top: 8px solid transparent
      border-bottom: 8px solid transparent
      border-left: 10px solid $benders-red
      right: 0.25rem
      bottom: 0.55rem

.is-link
  cursor: pointer
  color: $benders-red
  font-weight: bold
  padding: 0.5rem

  &[disabled=true]
    cursor: default
    opacity: 0.5

button.is-link
  background: none
  border: none

.toggle
  margin: 1rem 0
  padding: 1rem 1.2rem 1.2rem
  font-size: 1rem
  font-family: $family-primary
</style>
