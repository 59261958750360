<script setup lang="ts">
import type { ListProject, ListFilters } from '@/project';

import _ from 'lodash';
import { ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { getAvailableLists, deleteList, cancelRequest } from '@/api/api';
import { weightFormatter } from '@/helpers/formatters';
import { useAccountStore } from '@/store/account';
import { useProjectStore } from '@/store/project';
import { useI18n } from 'vue-i18n';

import Calendar from '../components/CalendarPicker.vue';
import BendersLoader from '../components/BendersLoader.vue';
import Pagination from './PaginationComponent.vue';
import isMobileMixin from '@/helpers/mobileMixin';

const { t } = useI18n();

const { getSession } = useAccountStore();
const project = useProjectStore();

const lists = ref<ListProject[]>([]);
const perPage = ref(10);
const pageViewMore = ref(0);
const page = ref(1);
const total = ref(0);
const pages = ref(0);
const loading = ref(false);
const loadingMore = ref(false);

const isMobile = isMobileMixin();

const filters = ref<ListFilters>({
  sort: '',
  date: {
    from: null,
    to: null,
  },
  id: '',
  customer: '',
  name: '',
  created_by: '',
  market: project.getMarket
});

watch(() => project.getMarket, market => {
  filters.value.market = market;
})

const setSort = (sort: string) => {
  if (sort === filters.value.sort) {
    filters.value.sort = `-${sort}`; // Change sort direction
  } else {
    filters.value.sort = sort; // Change sort field
  }
};

const debouncedSearch = _.debounce(
  async (append?: boolean) => {
    getResults(append);
  },
  250,
  {
    leading: true,
    trailing: true,
  },
);

const getResults = async (append?: boolean) => {
  loading.value = true; // Loading
  loadingMore.value = append ?? false;

  cancelRequest('availableLists'); // Cancel previous request (if any)

  const resp = await getAvailableLists(append ? page.value + pageViewMore.value : page.value, perPage.value, filters.value);
  total.value = resp.total; // Set total
  pages.value = resp.pages; // Set pages
  resp.result = resp.result || []; // Make sure we have lists
  lists.value = append ? [...lists.value, ...resp.result] : resp.result; // Set lists

  loading.value = false; // Finished loading
  loadingMore.value = false;
};

const deleteCalc = (id: string) => {
  // ask if we want to delete
  if (!confirm(t('project.delete.confirm', { id }))) {
    return;
  }

  deleteList(id).then(() => {
    getResults();
  });
};

const timeToLocal = (date: string) => {
  const d = new Date(date);
  return Intl.DateTimeFormat('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(d);
};

watch(
  () => [page.value, pageViewMore.value],
  (newValues, oldValues) => {
    // Determine which value changed
    const [page, pageViewMore] = newValues;
    const [oldPage, oldPageViewMore] = oldValues || [0, 0];
    if ((page === oldPage && pageViewMore === oldPageViewMore) || (page === oldPage && pageViewMore === 0)) {
      return;
    }

    const append = page === oldPage && pageViewMore !== oldPageViewMore;
    debouncedSearch(append);
  },
  { immediate: true },
);

watch(
  () => [filters.value, getSession],
  () => {
    // Reset pagination
    page.value = 1;
    pageViewMore.value = 0;
    // Search
    debouncedSearch();
  },
  { deep: true },
);

defineProps<{
  show: boolean;
}>();
</script>

<template>
  <div
    class="project-list"
    :class="{
      'is-hidden-touch': !show,
    }"
  >
    <div class="filter">
      <div>
        <div>
          <label for="created-date-from">{{ $t('date.from') }}</label>
        </div>
        <div><Calendar id="created-date-from" v-model="filters.date.from" /></div>
      </div>
      <div>
        <div>
          <label for="created-date-to">{{ $t('date.to') }}</label>
        </div>
        <div><Calendar id="created-date-to" v-model="filters.date.to" /></div>
      </div>
      <div>
        <div>
          <label for="calc-id">{{ $t('project.id') }}</label>
        </div>
        <div><input type="input" id="calc-id" class="input" v-model="filters.id" /></div>
      </div>
      <div>
        <div>
          <label for="customer">{{ $t('customer.customer') }}</label>
        </div>
        <div><input type="input" id="customer" class="input" v-model="filters.customer" /></div>
      </div>
      <div>
        <div>
          <label for="name">{{ $t('project.name') }}</label>
        </div>
        <div><input type="input" id="name" class="input" v-model="filters.name" /></div>
      </div>
      <div>
        <div>
          <label for="created_by">{{ $t('customer.created_by') }}</label>
        </div>
        <div><input type="input" id="name" class="input" v-model="filters.created_by" /></div>
      </div>
    </div>

    <template v-if="loading && !loadingMore">
      <BendersLoader :hidden="false" size="large" class="container"></BendersLoader>
    </template>
    <template v-else>
      <h2 class="title is-size-4 is-size-3-tablet is-uppercase has-text-centered">{{ total }} {{ $t('generic.calculations') }}</h2>

      <table v-if="!isMobile" class="table margin-xxxlarge-vertical is-fullwidth has-text-left">
        <thead>
          <tr>
            <th>
              <button @click.prevent="setSort('date')" class="sort button">
                {{ $t('project.id') }}
                <font-awesome-icon :icon="['fas', filters.sort === 'date' ? 'chevron-up' : 'chevron-down']" />
              </button>
            </th>
            <th>
              <button @click.prevent="setSort('customer')" class="sort button">
                {{ $t('customer.customer') }}
                <font-awesome-icon :icon="['fas', filters.sort === 'customer' ? 'chevron-up' : 'chevron-down']" />
              </button>
            </th>
            <th>
              <button @click.prevent="setSort('name')" class="sort button">
                {{ $t('project.name') }}
                <font-awesome-icon :icon="['fas', filters.sort === 'name' ? 'chevron-up' : 'chevron-down']" />
              </button>
            </th>
            <th>
              <button @click.prevent="setSort('created_by')" class="sort button">
                {{ $t('customer.created_by') }}
                <font-awesome-icon :icon="['fas', filters.sort === 'created_by' ? 'chevron-up' : 'chevron-down']" />
              </button>
            </th>
            <th>
              <button @click.prevent="setSort('weight')" class="sort button">
                {{ $t('generic.weight') }}
                <font-awesome-icon :icon="['fas', filters.sort === 'weight' ? 'chevron-up' : 'chevron-down']" />
              </button>
            </th>
            <th><!--Delete--></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(object, i) in lists" :key="i">
            <td class="has-text-weight-normal">
              <router-link
                :to="{
                  name: 'project',
                  params: { id: object.id || '0000000000' },
                }"
                class="is-size-6"
              >
                {{ object.id }}
              </router-link>
              <span class="is-block has-text-weight-normal is-size-7">
                {{ timeToLocal(object.created) }}
              </span>
            </td>
            <td v-if="object.owner && object.owner.type === 'customer' && object.owner.customerNumber">
              <span class="is-size-6">{{ object.owner.name }}</span>
              <span class="is-block is-size-7">{{ object.owner.customerNumber }}</span>
            </td>
            <td v-else></td>
            <td class="is-size-6 is-uppercase">{{ object.name }}</td>
            <td v-if="object.created_by && object.created_by.firstName">{{ object.created_by.firstName }} {{ object.created_by.lastName }}</td>
            <td v-else></td>
            <td class="is-size-6 nowrap">
              {{ weightFormatter(object.weight) + 'kg' }}
            </td>
            <td class="has-text-centered">
              <a class="is-danger is-small" @click="deleteCalc(object.id)">
                <font-awesome-icon icon="trash" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table mobile-table is-size-7 margin-xxxlarge-vertical is-fullwidth has-text-left" v-else>
        <tbody>
          <tr v-for="(object, i) in lists" :key="i">
            <td>
              <dl>
                <dt>{{ $t('project.id') }}</dt>
                <dd>
                  <router-link
                    :to="{
                      name: 'project',
                      params: { id: object.id || '0000000000' },
                    }"
                    class="is-size-7"
                  >
                    {{ object.id }}
                  </router-link>
                </dd>
                <dt>{{ $t('project.created') }}</dt>
                <dd>{{ timeToLocal(object.created) }}</dd>
                <dt>{{ $t('customer.customer') }}</dt>
                <dd>
                  <template v-if="object.owner && object.owner.type === 'customer' && object.owner.customerNumber">
                    <span class="is-size-7">{{ object.owner.name }}</span>
                    <span class="is-block is-size-7">{{ object.owner.customerNumber }}</span>
                  </template>
                </dd>
                <dt>{{ $t('project.name') }}</dt>
                <dd>{{ object.name }}</dd>
                <dt>{{ $t('customer.created_by') }}</dt>
                <dd>
                  <template v-if="object.created_by && object.created_by.firstName">
                    {{ object.created_by.firstName }} {{ object.created_by.lastName }}
                  </template>
                </dd>
                <dt>{{ $t('generic.weight') }}</dt>
                <dd>{{ weightFormatter(object.weight) + 'kg' }}</dd>
              </dl>
              <a class="is-danger is-small is-size-7" @click="deleteCalc(object.id)"> <font-awesome-icon icon="trash" /> {{ $t('generic.delete') }} </a>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <Pagination
      @pagination="
        page = $event;
        pageViewMore = 0;
      "
      :current="page"
      :total="Math.ceil(total / perPage)"
      :start_size="1"
      :mid_size="2"
      :end_size="1"
      :viewmore="(page + pageViewMore) * perPage < total"
      :viewmore_count="pageViewMore"
      :viewmore_loading="loading"
      @viewmore="pageViewMore = $event"
    />
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.intro
  max-width: 670px
  margin: 0 auto

.image
  height: 200px

.mobile-table
    dl
        display: grid
        grid-template-columns: repeat(2, minmax(0,1fr))
    dt
        font-weight: bold
        margin-bottom: 0.5rem

.new-calc
  margin-bottom: 1.2rem
  a
    position: relative
    padding-right: 1.55rem
    &:after
      content: ''
      display: block
      width: 0
      height: 0
      position: absolute
      border-top: 8px solid transparent
      border-bottom: 8px solid transparent
      border-left: 10px solid $benders-red
      right: 0.25rem
      bottom: 0.55rem

div.filter
  display: grid
  gap: 1rem
  margin-bottom: 3rem
  @media screen and (min-width: 769px)
    grid-template-columns: repeat(6, minmax(0,1fr))
  > div
    display: grid
    grid-template-columns: repeat(2, minmax(0,1fr))
    align-items: center
    @media screen and (min-width: 769px)
      align-items: flex-start
      grid-template-columns: 1fr
  label
    font-size: 0.875rem
    font-weight: 700
    text-transform: uppercase
    text-align: left
    display: block
    color: $gray-11
  div
    .icon-wrap
      position: relative
      .calendar
        color: $gray-3
        position: absolute
        top: 0.65rem
        right: 0.65rem

.is-link
  cursor: pointer
  color: $benders-red
  font-weight: bold
  padding: 0.5rem

  &[disabled=true]
    cursor: default
    opacity: 0.5

button.is-link, .sort.button
  background: none
  border: none
  &:hover, &:active, &:focus
    background: none
    border: none
.toggle
  margin: 1rem 0
  padding: 1rem 1.2rem 1.2rem
  font-size: 1rem
  font-family: $family-primary

th
  text-transform: uppercase

.table td, table td
  vertical-align: middle

thead tr th:not(.filter th)
  color: $benders-red
  font-size: 0.875rem
  text-transform: uppercase
  font-weight: 600
  padding: 0 11px 0 11px
  vertical-align: middle

.sort.button
  padding: 0
  color: $benders-red
  font-size: 0.875rem
  text-transform: uppercase
  font-weight: 600
  // &:focus
  //   outline: 2px solid black
  //   border-radius: 2px
  svg
    margin-left: 0.25rem
    width: 0.53rem
    color: $gray-11
</style>
