import { createI18n } from 'vue-i18n';
import deDE from './languages/de_DE.json';
import enUS from './languages/en_US.json';
import fiFI from './languages/fi_FI.json';
import nbNO from './languages/nb_NO.json';
import svSE from './languages/sv_SE.json';

const i18n = createI18n({
  legacy: false,
  locale: 'sv-SE',
  supportedLocales: ['de-DE', 'en-US', 'fi-FI', 'nb-NO', 'sv-SE'],
  globalInjection: true,
  messages: {
    'de-DE': deDE,
    'en-US': enUS,
    'fi-FI': fiFI,
    'nb-NO': nbNO,
    'sv-SE': svSE,
  },
});

export { i18n };
