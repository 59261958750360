import type { ProjectData, Roof, Tile, Extension, Accessories, Extras, ExtraArticle } from '@/project';
import {
  AccessoryColor,
  Sealant,
  Ventilation,
  ExposedWeather,
  FixingsTile,
  FixingsNock,
  Gable,
  DrainAerator,
  VentilationHood,
  BattenStep,
  SecuritySystem,
  BTSBaseRoof,
  NockBoard,
  ConcealedDraining,
  SealingAiring,
  FacadeHeight,
  SnowSlideObstacle,
} from '@/enums';
import {
  RoofTypeIndex,
  Underlagstak,
  NockPannaVariant,
  NockPannaExtra,
  NockConnector,
  TileFamily,
  TileFinish,
  TileColor,
  LathDistance,
} from '@/calculation/common';

import { i18n } from '@/i18n';
const { t } = i18n.global;

// import _ from 'lodash'

const defaults = {
  roof: {
    id: '',
    name: t('defaults.roof.name'),
    roof: {
      type: RoofTypeIndex.None,
      amount: 1,
      dimensions: {
        a: 0,
        b: 0,
        c: 0,
        angle: 0,
        angle2: 0,
        slope: 0,
        slope2: 0,
        facadeHeight: FacadeHeight.None,
        exposedWeather: ExposedWeather.No,
        lathDistance: LathDistance.Calculated,
        lathMeasurement: 375,
        baseRoof: Underlagstak.RåspontMedPapp,
      },
    },
    tile: {
      family: TileFamily.None,
      coating: TileFinish.None,
      color: TileColor.None,
      nock: NockPannaVariant.Standard,
      nockExtra: NockPannaExtra.None,
      gable: Gable.None,
      nockConnector: NockConnector.Yes,
    } as Tile,
    accessories: {
      color: AccessoryColor.None,
      drainAerator: {
        type: DrainAerator.None,
        amount: 1,
      },
      ventilationHood: {
        type: VentilationHood.Plate,
        amount: 1,
      },
      sealant: Sealant.None,
      ventilation: Ventilation.Birdband1m,
      concealedDraining: ConcealedDraining.Yes,
      sealingAiring: SealingAiring.Yes,
      fixingTile: FixingsTile.None,
      extraFixingTiles: 0,
      fixingNock: FixingsNock.None,
      nockBoard: NockBoard.Yes,
    } as Accessories,
    security: {
      battenStep: {
        type: BattenStep.None,
        amount: 0,
      },
      mountingRail: 1,
      trackTilesForBattenSteps: true,
      trackingTiles: [],
      slipProtection: 1,
      roofHatch: 0,
      safetyHook: 0,
      snowHook: 0,
      snowSlideObstacle: SnowSlideObstacle.Yes,
      iceStopper: 0,
      securitySystem: {
        type: SecuritySystem.None,
        snowSafety: [],
        roofBridge: [],
        hatchSafety: [],
        railsAmount: 0,
        snowZone: 0,
        fasteningEye: 0,
      },
    },
    extras: {
      articles: {} as { [key: string]: ExtraArticle },
      btsBaseRoof: BTSBaseRoof.No,
      // articles: [] as ExtraArticle[],
    } as Extras,
    extensions: [] as Extension[],
    editing: {
      roof: true,
    },
    step: {},
    valueLocked: {},
  } as Roof,
  data: {} as ProjectData,
};
defaults.data = {
  roofs: [defaults.roof] as Roof[],
} as ProjectData;

export { defaults };
