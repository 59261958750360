<script setup lang="ts">
import { computed } from "vue";

import Datepicker from "@vuepic/vue-datepicker";
// import '@vuepic/vue-datepicker/dist/main.css';

const date = computed<Date | undefined | null>({
  get: () => {
    return prop.modelValue;
  },
  set: (value: Date | undefined | null) => {
    emit("update:modelValue", value);
  },
});

const format = "yyyy-MM-dd";

const prop = defineProps<{
  modelValue?: Date | undefined | null;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: Date | undefined | null): void;
}>();
</script>

<template>
  <div>
    <Datepicker
      v-model="date"
      :enableTimePicker="false"
      :placeholder="$t('date.placeholder')"
      textInput
      locale="se"
      autoApply
      :cancelText="$t('date.cancel')"
      :selectText="$t('date.pickdate')"
      :format="format"
      :previewFormat="format"
    >
      <template #input-icon>
        <font-awesome-icon :icon="['far', 'calendar']" class="calendar" />
      </template>
      <template #now-button="{ selectCurrentDate }">
        <span
          @click="selectCurrentDate()"
          :title="$t('date.today')"
          class="button"
        >
          {{ $t("date.today") }}
        </span>
      </template>
    </Datepicker>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.sass";

$dp__font_family: $family-primary;

@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #a6192e;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #a6192e;
  --dp-success-color-disabled: #a6192e81;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}

.dp__input {
  height: 2.5rem;
  font-size: 1rem;
  border-radius: 0;
  border-width: 2px;
  &::placeholder {
    color: rgba(54, 54, 54, 0.3);
    opacity: 1;
  }
  &_icon {
    right: 0.65rem;
    left: unset;
    color: $gray-3;
    &_pad {
      padding-left: 12px;
    }
  }
}

.dp__clear_icon {
  right: 1.65rem;
}
</style>
