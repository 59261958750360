import type { Roof } from '@/project';
import type { ValidationSelector, ValidationOptions } from '@/types';
import { StepState, ValidationSteps } from '@/enums';
import { defineStore } from 'pinia';

import { i18n } from '@/i18n';
const { t } = i18n.global;

const selectorToString = (selector: ValidationSelector) => {
  return `${selector.step}-${selector.roofIndex ?? 'none'}-${selector.extensionIndex ?? 'none'}`;
};

export const useValidationStore = defineStore({
  id: 'validation',
  state: () => ({
    // {name}-{roofIndex}-{extensionIndex}
    valid: {} as { [key: string]: boolean },
    active: {
      'rooftype-0-none': true, // Note: The initial active step should probably be set somewhere else
    } as { [key: string]: boolean },
    errors: {} as { [key: string]: { [key: string]: string } },
    calc_errors: {} as { [key: string]: { [key: string]: string } },
  }),
  getters: {
    stepActive: (state) => (selector: ValidationSelector) => {
      return state.active[selectorToString(selector)] === true;
    },
    stepValid: (state) => (selector: ValidationSelector) => {
      return state.valid[selectorToString(selector)] === true;
    },
    stepError: (state) => (selector: ValidationSelector) => {
      return state.valid[selectorToString(selector)] === false;
    },
    stepDone: (state) => (selector: ValidationSelector) => {
      return state.valid[selectorToString(selector)] === true && state.active[selectorToString(selector)] === false;
    },
    stepExists: (state) => (selector: ValidationSelector) => {
      return state.valid[selectorToString(selector)] !== undefined || state.active[selectorToString(selector)] !== undefined;
    },
    stepIsOne: (state) => (selector: ValidationSelector, states: StepState[]) => {
      // Note: Check if this can use the other getters in some way, linter was complaining
      for (const s in states) {
        switch (states[s]) {
          case StepState.Active:
            if (state.active[selectorToString(selector)] === true) {
              return true;
            }
            break;
          case StepState.Valid:
            if (state.valid[selectorToString(selector)] === true) {
              return true;
            }
            break;
          case StepState.Error:
            if (state.valid[selectorToString(selector)] === false) {
              return true;
            }
            break;
          case StepState.Done:
            if (state.valid[selectorToString(selector)] === true && state.active[selectorToString(selector)] === false) {
              return true;
            }
            break;
        }
      }
      return false;
    },
    roofStepsAreValid:
      (state) =>
      (roofIndex: number, ...steps: ValidationSteps[]) => {
        for (const s in state.valid) {
          if (steps.includes(s.split('-')[0] as ValidationSteps) && s.split('-')[1] === roofIndex.toString() && state.valid[s] === false) {
            return false;
          }
        }
        return true;
      },
    extensionsAreValid:
      (state) =>
      (roofIndex: number, ...steps: ValidationSteps[]) => {
        for (const s in state.valid) {
          if (steps.includes(s.split('-')[0] as ValidationSteps) && s.split('-')[1] === roofIndex.toString() && state.valid[s] === false) {
            return false;
          }
        }
        return true;
      },
    stepsAreValid:
      (state) =>
      (...steps: ValidationSteps[]) => {
        for (const s in state.valid) {
          if (steps.includes(s.split('-')[0] as ValidationSteps) && state.valid[s] === false) {
            return false;
          }
        }
        return true;
      },
    hasError: (state) => (selector: ValidationSelector, key: string) => {
      return state.errors[selectorToString(selector)]?.[key] ?? state.calc_errors[selectorToString(selector)]?.[key];
    },
    validSteps: (state) => {
      // summerize the valid steps by the first part of the key
      const validSteps = {} as { [key: string]: boolean };
      for (const s in state.valid) {
        validSteps[s.split('-')[0]] = validSteps[s.split('-')[0]] !== false && state.valid[s];
      }
      return validSteps;
    },
  },
  actions: {
    validate(option: ValidationOptions, data: { [key: string]: unknown }, roof: Roof): { [key: string]: string } | null {
      const rules = option.rules;
      const selector = option.selector;
      const errors = {} as { [key: string]: string };
      for (const key in data) {
        const value = data[key];
        const rule = rules?.[key];
        if (rule) {
          if (rule.notNull && (value === null || value === undefined)) {
            errors[key] = t('error.validation.notNull');
            continue;
          }
          if (rule.notEmpty && (Array.isArray(value) || typeof value === 'string') && value.length === 0) {
            errors[key] = t('error.validation.notEmpty');
            continue;
          }
          if (rule.notZero && value === 0) {
            errors[key] = t('error.validation.notZero');
            continue;
          }
          const [valid, message] = rule.validator ? rule.validator(value, roof) : [true, undefined];
          if (!valid) {
            errors[key] = message ?? t('error.validation.invalid');
            continue;
          }
        } else {
          console.warn(`Validation: No rule defined for key '${key}'`);
        }
      }

      if (Object.keys(errors).length > 0) {
        this.setStepError(selector, errors);
        console.warn('Validation errors', selector, errors);
        return errors;
      }

      this.setStepValid(selector);
      return null;
    },
    setStepValid(selector: ValidationSelector) {
      delete this.errors[selectorToString(selector)];
      if (this.stepValid(selector)) return;
      this.valid[selectorToString(selector)] = true;
    },
    setStepError(selector: ValidationSelector, errors?: { [key: string]: string }) {
      if (errors) this.errors[selectorToString(selector)] = errors;
      if (this.stepError(selector)) return;
      this.active[selectorToString(selector)] = this.stepActive(selector);
      this.valid[selectorToString(selector)] = false;
    },
    setStepDone(selector: ValidationSelector) {
      delete this.errors[selectorToString(selector)];
      if (this.stepDone(selector)) return;
      this.active[selectorToString(selector)] = false;
      this.valid[selectorToString(selector)] = true;
    },
    setStepActive(selector: ValidationSelector) {
      // Set all other steps to inactive
      for (const key in this.active) {
        this.active[key] = false;
      }
      this.active[selectorToString(selector)] = true;
    },

    // Cleanup functions
    removeStep(selector: ValidationSelector) {
      delete this.active[selectorToString(selector)];
      delete this.valid[selectorToString(selector)];
    },
    removeExtensionSteps(roofIndex: number, extensionIndex: number) {
      for (const key in this.valid) {
        if (key.endsWith(`-${roofIndex}-${extensionIndex}`)) {
          delete this.valid[key];
        }
      }
      for (const key in this.active) {
        if (key.endsWith(`-${roofIndex}-${extensionIndex}`)) {
          delete this.active[key];
        }
      }
      for (const key in this.errors) {
        if (key.endsWith(`-${roofIndex}-${extensionIndex}`)) {
          delete this.errors[key];
        }
      }
    },
    removeRoofSteps(roofIndex: number) {
      const regex = new RegExp(`-${roofIndex}-\\w+$`);
      for (const key in this.valid) {
        if (regex.test(key)) {
          delete this.valid[key];
        }
      }
      for (const key in this.active) {
        if (regex.test(key)) {
          delete this.active[key];
        }
      }
      for (const key in this.errors) {
        if (regex.test(key)) {
          delete this.errors[key];
        }
      }
    },
    cleanupSteps(roofCount: number) {
      // Remove steps that the roof count is too low for
      for (const key in this.valid) {
        const parts = key.split('-');
        if (parseInt(parts[1]) >= roofCount) {
          delete this.valid[key];
        }
      }
      for (const key in this.active) {
        const parts = key.split('-');
        if (parseInt(parts[1]) >= roofCount) {
          delete this.active[key];
        }
      }
      for (const key in this.errors) {
        const parts = key.split('-');
        if (parseInt(parts[1]) >= roofCount) {
          delete this.errors[key];
        }
      }
    },
  },
});
