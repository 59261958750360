<script setup lang="ts">
import { computed } from "vue";

const listItems = computed(() => {
  let items = [];
  let prefix_dots = false;
  let suffix_dots = false;
  let start_size = 0 + prop.start_size;
  let lower_mid_size = Math.max(
    0 + prop.start_size,
    prop.current - prop.mid_size
  );
  let upper_mid_size = Math.max(
    0 + prop.start_size,
    prop.current + prop.mid_size
  );
  let end_size = prop.total - prop.end_size + 1;

  for (let i = 1; i <= prop.total; i++) {
    if (i > start_size && i < lower_mid_size) {
      if (!prefix_dots) items.push(-1);
      prefix_dots = true;
    } else if (i > upper_mid_size && i < end_size) {
      if (!suffix_dots) items.push(-1);
      suffix_dots = true;
    } else {
      items.push(i);
    }
  }

  return items;
});

const paginationClick = (page: number) => {
  emit("pagination", page);
  // window.history.pushState(
  //   "",
  //   "",
  //   window.location.search.match(/page=[0-9]+/i)
  //     ? window.location.search.replace(/page=[0-9]+/i, "page=" + page)
  //     : window.location.search === ""
  //     ? "?page=" + page
  //     : window.location.search + "&page=" + page
  // ); // Update url
};

const viewmoreClick = (viewmore: number) => {
  emit("viewmore", viewmore);
  // window.history.pushState(
  //   "",
  //   "",
  //   window.location.search.match(/viewmore=[0-9]+/i)
  //     ? window.location.search.replace(
  //         /viewmore=[0-9]+/i,
  //         "viewmore=" + viewmore
  //       )
  //     : window.location.search === ""
  //     ? "?viewmore=" + viewmore
  //     : window.location.search + "&viewmore=" + viewmore
  // ); // Update url
};

const emit = defineEmits<{
  (event: "pagination", page: number): void;
  (event: "viewmore", page: number): void;
}>();

const prop = defineProps({
  base: {
    type: String,
    default: "",
  },
  total: {
    type: Number,
    default: 0,
  },
  current: {
    type: Number,
    default: 1,
  },
  prev_text: {
    type: String,
    default: "←",
  },
  next_text: {
    type: String,
    default: "→",
  },
  start_size: {
    type: Number,
    default: 3,
  },
  mid_size: {
    type: Number,
    default: 3,
  },
  end_size: {
    type: Number,
    default: 3,
  },
  viewmore: Boolean,
  viewmore_count: {
    type: Number,
    default: 0,
  },
  viewmore_loading: Boolean,
});
</script>

<template>
  <div class="container">
    <nav v-if="total > 1" class="pagination is-small">
      <div v-if="viewmore" class="viewmore">
        <button
          class="button is-link"
          :disabled="viewmore_loading"
          @click.prevent="viewmoreClick(viewmore_count + 1)"
        >
          Visa mer
        </button>
      </div>
      <ul class="page-numbers">
        <li :class="[current === 1 ? 'is-invisible' : null]">
          <a
            @click.prevent="paginationClick(current - 1)"
            class="prev page-numbers"
            :href="base + (current - 1)"
            >{{ prev_text }}</a
          >
        </li>
        <li v-for="(index, i) in listItems" :key="'pagination' + index + i">
          <a
            v-if="
              viewmore_count > 0 &&
              index >= current &&
              index <= current + viewmore_count
            "
            @click.prevent="paginationClick(index)"
            :href="base + index"
            aria-current="page"
            class="page-numbers current"
            >{{ index }}</a
          >
          <span
            v-else-if="index === current"
            aria-current="page"
            class="page-numbers current"
            >{{ index }}</span
          >
          <span v-else-if="index === -1" class="page-numbers dots">...</span>
          <a
            v-else
            @click.prevent="paginationClick(index)"
            :class="['page-numbers']"
            :href="base + index"
            >{{ index }}</a
          >
        </li>
        <li
          :class="[current + viewmore_count >= total ? 'is-invisible' : null]"
        >
          <a
            @click.prevent="paginationClick(current + viewmore_count + 1)"
            class="next page-numbers"
            :href="base + (current + viewmore_count + 1)"
            >{{ next_text }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="sass">
.pagination
  max-width: 100%
  align-items: center
  display: flex
  justify-content: center
  text-align: center
  &.is-small
    font-size: 0.875rem
  ul
    align-items: center
    display: flex
    flex-shrink: 1
    flex-grow: 1
    justify-content: center
    text-align: center
    li > *
      -moz-appearance: none
      -webkit-appearance: none
      align-items: center
      border: 1px solid transparent
      border-radius: 0
      box-shadow: none
      display: inline-flex
      height: 2.5em
      justify-content: flex-start
      line-height: 1.5
      padding-bottom: calc(0.5em - 1px)
      padding-left: calc(0.75em - 1px)
      padding-right: calc(0.75em - 1px)
      padding-top: calc(0.5em - 1px)
      position: relative
      vertical-align: top

      font-size: 1em
      justify-content: center
      margin: 0.25rem
      padding-left: 0.5em
      padding-right: 0.5em
      text-align: center
      border-color: transparent
      color: #464749
      min-width: 2.5em

  .pagination-link.is-current, .pagination-link.current, li > .is-current, li > .current,.viewmore button
    background-color: #a6192e
    border-color: transparent
    color: #fff

  .pagination-link.is-current, .woocommerce-pagination .pagination-link.current, .woocommerce-pagination li > .is-current, .woocommerce-pagination li > .current, .woocommerce-pagination .viewmore button
    background-color: #a6192e
    border-color: transparent
    color: #fff

  .pagination-list
    flex-grow: 0
    .pagination-next, .pagination-previous
      flex-grow: 0

.is-invisible
  visibility: hidden !important
</style>