import type { Article, ArticleMap } from '@/types';

import { defineStore } from 'pinia';
import { getArticlesByPrefix, getArticles, getArticle, getAccessories } from '@/api/api';

import _ from 'lodash';

export const useArticleStore = defineStore({
  id: 'article',
  state: () => ({
    cache: {} as ArticleMap,
    prefix: {} as { [key: string]: string[] },
    promises: {} as { [key: string]: Promise<ArticleMap> },
    accessories: {} as { [key: string]: string[] },
  }),
  getters: {},
  actions: {
    addCache(articles: Article[]) {
      articles.forEach((a) => {
        this.cache[a.ArtNr] = a;
      });
    },
    addPrefixCache(prefix: string, articles: Article[]) {
      this.addCache(articles);
      this.prefix[prefix] = articles.map((art) => art.ArtNr);
    },
    clearCache() {
      this.cache = {};
      this.prefix = {};
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getArticlesByPrefix(prefix: string, noCache?: boolean, params?: Record<string, unknown>, signal?: AbortSignal): Promise<ArticleMap> {
      // Get articles from cache
      if (!noCache && this.prefix?.[prefix]?.length > 0) {
        return this.prefix[prefix].reduce((acc, cur) => ({ ...acc, [cur]: this.cache[cur] }), {} as ArticleMap);
      }

      // Fetch from API
      if (noCache || !this.promises[prefix]) {
        this.promises[prefix] = getArticlesByPrefix(prefix, params, signal);
      }
      const articles = await this.promises[prefix];

      // Store keys in prefix cache
      this.prefix[prefix] = Object.keys(articles);

      // Store articles in cache
      this.addCache(Object.values(articles));

      return articles;
    },
    async getArticles(artnr: string[], noCache?: boolean): Promise<ArticleMap> {
      // Separate artnrs into cached and not cached
      const [available, remaining] = _.partition(artnr, (artnr) => artnr in this.cache && !noCache);

      // if no articles are missing, return the cached articles
      let newArticles = {} as ArticleMap;
      if (remaining.length > 0) {
        // Fetch from API
        newArticles = (await getArticles(remaining)) as ArticleMap;

        // Store articles in cache
        this.addCache(Object.values(newArticles));
      }

      return available.reduce((acc, cur) => ({ [cur]: this.cache[cur], ...acc }), newArticles);
    },
    async getArticle(artnr: string, noCache?: boolean): Promise<Article> {
      // Return cached version if available
      if (!noCache && artnr in this.cache) {
        return new Promise((res) => res(this.cache[artnr]));
      }

      // Fetch from API
      const article = await getArticle(artnr);

      // Store article in cache
      this.cache[article.ArtNr] = article;

      return article;
    },
    async getAccessories(
      {
        assortment,
        colornumber,
        colorgroup,
        tilefamily,
        excludeTileFamily,
      }: { assortment: string; colornumber?: string[]; colorgroup?: string[]; tilefamily?: string; excludeTileFamily?: string[] },
      noCache?: boolean,
    ): Promise<ArticleMap> {
      const cacheKey = `${assortment}-${colornumber?.join('-')}-${colorgroup?.join('-')}-${tilefamily}`;

      // Get articles from cache
      if (!noCache && cacheKey in this.accessories) {
        return this.accessories[cacheKey].reduce((acc, cur) => ({ ...acc, [cur]: this.cache[cur] }), {} as ArticleMap);
      }

      // Fetch from API
      const articles = (await getAccessories(assortment, colornumber, colorgroup, tilefamily, excludeTileFamily)) as ArticleMap;

      // Store keys in prefix cache
      this.accessories[cacheKey] = Object.keys(articles);

      // Store articles in cache
      this.addCache(Object.values(articles));

      return articles;
    },
  },
});
