import { defineStore } from 'pinia';
import axios from 'axios';

import { i18n } from '@/i18n';
import type { LanguageTag } from '@/types';
import { MarketStr } from '@/calculation/common';

const authInstance = axios.create({
  baseURL: import.meta.env.VITE_AUTH_API_BASE_URL,
});

const wpInstance = axios.create({
  baseURL: import.meta.env.VITE_WP_API_BASE_URL,
});

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    user: {
      id: '',
      isBendersEmployee: false,
      customers: [],
    },
    session: '',
    region: MarketStr.None,
  }),
  getters: {
    getSession: (state) => state.session,
    getUser: async (state) => {
      if (!state.user?.id && state.session) {
        const res = await authInstance.get('session');
        state.user = res.data;
      }
      return state.user;
    },
    getLanguage: () => {
      const { locale } = i18n.global;
      return locale.value as LanguageTag;
    },
  },
  actions: {
    setSession(session = '') {
      this.session = session;
      // Set cookie if it doesn't exist
      // if (!document.cookie.split(';').find((cookie) => cookie.trim().startsWith('benders_logged_in='))) {
      //   document.cookie = `benders_logged_in=${session}`
      // }
      authInstance.defaults.headers.common['Authorization'] = `Bearer ${session}`;
      wpInstance.defaults.headers.common['Authorization'] = `Bearer ${session}`;
    },
    // Search for any customer. Only for benders users.
    async findCustomers(query = '', selected = '', filters = {}) {
      const res = await wpInstance.get('/customers', { params: { s: query, selected, f: JSON.stringify(filters) } });
      return res.data?.data ?? [];
    },
    // Get customers that the current user is allowed to see/use.
    // Current user is extrapolated from the session token.
    async getCustomers() {
      const res = await wpInstance.get('/current_user_customers', { params: { all: true } });
      return res.data?.data ?? [];
    },
    setLanguage(lang: LanguageTag) {
      const { locale } = i18n.global;
      locale.value = lang;
    },
    // This doesn't control the current market, it just sets what market the user can select languages from.
    setRegion(region: MarketStr) {
      this.region = region;
    },
  },
});
