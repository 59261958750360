import { ref } from 'vue';

export default function isMobileMixin() {
  const isMobile = ref(false);

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth < 768;
  };

  window.addEventListener('resize', updateIsMobile);
  updateIsMobile();

  return isMobile;
}
