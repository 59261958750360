import type { RoofTile } from '@/calculation/rooftiles/rooftile';
import type { RadioOption } from '@/types';
import type { TileFamily, RoofArticle, MarketStr, Underlagstak, TileFinish, TileColor } from '@/calculation/common';
import { doArticleReplacements } from '@/helpers/tile';
import type { AccessoryColor } from '@/enums';

export class OptionsBuilder {
  default = 0;
  market: MarketStr;
  options: number[] = [];
  optionMap: { [key: number]: RoofArticle } = {};
  // itemNos: { [key: string]: string } = {};
  rooftile: RoofTile;

  constructor(rooftile: RoofTile, market: MarketStr, optionMap: { [key: number]: RoofArticle }) {
    // this.itemNos = itemNos;
    this.rooftile = rooftile;
    this.market = market;
    this.optionMap = optionMap;

    // Add all options
    for (const option of Object.keys(optionMap)) {
      this.options.push(Number(option));
    }
  }

  protected remove(...options: (number | null | undefined)[]): boolean {
    this.options = this.options.filter((o) => !options.includes(o));
    return true;
  }

  protected keep(...options: (number | null | undefined)[]): boolean {
    this.options = this.options.filter((o) => options.includes(o));
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  family(family: TileFamily): OptionsBuilder {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  finish(finish: TileFinish): OptionsBuilder {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  color(color: TileColor): OptionsBuilder {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  accessoryColor(color: AccessoryColor): OptionsBuilder {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  angle(angle: number): OptionsBuilder {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  underlagstak(underlagstak: Underlagstak): OptionsBuilder {
    return this;
  }

  // Custom filter function
  filter(fn: (option: number) => boolean): OptionsBuilder {
    this.options = this.options.filter(fn);
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDefault(family: TileFamily, underlagstak: Underlagstak, market: MarketStr): OptionsBuilder {
    return this;
  }

  build(): RadioOption[] {
    return doArticleReplacements(
      this.options.map((option) => {
        const mapped = this.optionMap[option];
        return {
          value: option,
          label: this.rooftile.getArticle(mapped), // overwritten by label in component
          artnr: this.rooftile.getArticle(mapped),
          default: option === this.default,
        };
      }),
    );
  }
}
